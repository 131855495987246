/* Full layout container, ensures it takes the full height of the viewport */
.app-layout {
    display: flex;
    min-height: 100vh;
    /* or height: 100vh; depending on your layout needs */
}
/* Styles for the sidebar */
.sidebar {
    width: 255px;
    /* Adjust the width of your sidebar */
    height: 100vh;
    /* Full height of the viewport */
    position: sticky;
    /* Stick to the top when you scroll */
    top: 0;
    overflow-y: auto;
    /* Scrollable if the content is larger than the viewport */
    background: #333;
    /* Background color for the sidebar */
    color: white;
    /* Text color for the sidebar */
    /* Add any additional styling such as padding or margins as needed */
}

/* Styles for the main content area */
.main-content {
    flex-grow: 1;
    /* Takes the remaining width */
    padding: 0px;
    /* Padding around the content */
    overflow-y: auto;
    /* Allows the content area to scroll independently if needed */
    margin-left: 50px;
    /* Should match the sidebar width to avoid overlap */
}


/* You can also add styles for the links within the sidebar */
.playlist-link {
    display: block;
    color: white;
    /* Example link color */
    padding: 10px;
    text-decoration: none;
}

.playlist-link.active {
    background: #666;
    /* Example active link background color */
}


.sidebar {
    position: sticky;
    top: 0;
    /* Aligns to the top of the viewport */
}

.parent-container {
    overflow: hidden;
    /* This should not be hidden if you want the sidebar to scroll */
}

.sidebar {
    z-index: 10;
    /* Adjust z-index as necessary */
}
