/* PlaylistStyles.css */
.playlist-container {
    display: flex;
    flex-direction: column;
}

.fetch-section {
    text-align: right;
    /* Center the button and messages */
}

.playlist-title {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: left;
    /* This will align the text to the left */
    font-size: 36px;
    padding-left: 50px;
    /* Spacing between the button/messages and the title */
}

.modern-table {
    width: 100%;
    border-collapse: collapse;
}

.success-message {
    color: green;
    /* Style for success message */
}

.error-message {
    color: red;
    /* Style for error message */
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
  grid-column: 1 / -1; /* Makes the table span all columns */
}

.modern-table th {
    background-color: #c6d4d3;
    /* Adjust the color as desired */
    color: black;
    /* Adjust text color as needed */
    padding: 12px 15px;
    font-size: 18px;
    /* Increase the font size as needed */
}
.modern-table td {
  padding: 12px 15px;
}

.modern-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.modern-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.modern-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.modern-table tbody tr:hover {
    background-color: #c6d4d3;
    /* Color for row hover */
    cursor: pointer;
}

.modern-table tbody tr {
        cursor: pointer;
        /* Changes the cursor to indicate the row is clickable */
}

/* Add additional styling as needed */