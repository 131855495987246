/* src/styles.css */
body {
    background: #fff;
  }
  
  #wrap {
    margin: 45px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%; /* Allow the container to grow */
    max-width: 100%; /* Remove the fixed max-width */
  }
  
  .chart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .chart-box {
    flex: 1;
    padding: 10px;
    min-width: 300px; /* Set minimum width */
    max-width: 100%; /* Allow the box to grow */
  }
  
  #chart-year,
  #chart-quarter {
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: none;
    transition: transform 1s ease;
  }
  
  #chart-year {
    z-index: 3;
  }
  
  #chart-quarter {
    z-index: -2;
  }
  
  #chart-quarter.active {
    z-index: 1;
  }
  
  @media screen and (max-width: 800px) {
    .chart-container {
      flex-direction: column;
    }
  
    #chart-year,
    #chart-quarter {
      max-width: 100%;
    }
  }
  
  #chart-quarter {
    display: none; /* Initially hidden */
  }
  
  #chart-quarter.active {
    display: block; /* Display when active */
  }
  