/* PlaylistDetailStyles.css */
.playlist-container {
    display: flex;
    flex-direction: column;
}

.playlist-title {
    margin-top: 15px;
}

.modern-table {
    width: 100%;
    border-collapse: collapse;
}

.modern-table th,
.modern-table td {
    padding: 12px 15px;
}

.modern-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.modern-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.modern-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.image-generator-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.image-generator-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.image-generator-box p {
    margin: 0 0 10px 0;
    font-weight: bold;
}

.image-generator-box button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.image-generator-box button:hover {
    background-color: #0056b3;
}

/* Ensure these rules are completely deleted or remain commented out to prevent hover color change */
/* .modern-table tbody tr:hover {
    background-color: #c6cdd4;
}

.modern-table tbody tr {
    cursor: pointer;
} */

.success-message {
    color: green;
}

.error-message {
    color: red;
}

.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Content {
    /* Other styles... */
    position: fixed;
    /* Change to fixed */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Rest of your styles... */
}

/* Add styles for the buttons if necessary */
button {
    cursor: pointer;
    margin: 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
}

/* Style specifically for the close button if you want */
.close-button {
    background-color: #f44336;
    /* Example color */
    color: white;
}

.apexcharts-title-text {
    padding-bottom: 20px;
    /* This adds padding below the title text */
}

.radar-chart-container {
    padding-top: 20px;
    /* Adds padding to the top of the chart container */
    width: 100%;
    /* Use 100% to utilize all the available width */
    max-width: 575px;
    /* Or as much as needed, but can be adjusted */
    margin: 20px auto;
    /* This ensures the chart is centered with some margin */
}
/* Add additional styling as needed */